import moment from 'moment';

export const createPayload = (data) => {
  const params = JSON.parse(JSON.stringify(data));
  const locationOneDate = moment(data.location_one.w_Appointment_Date).format('MM/DD/YYYY');
  const locationOneTime = moment(data.location_one.w_Appointment_Time).format('hh:mm A');

  params.location_one.cansubmitinvoice = 1;
  params.location_one.f_offering_fee = 60.00;
  params.location_one.w_sql_appointment_date = moment(data.location_one.w_Appointment_Date, 'YYYY-MM-DD');
  params.location_one.w_Appointment_Date = locationOneDate;
  params.location_one.f_appointment_date = locationOneDate;
  params.location_one.f_borrower_first_name = params.location_one.w_Borrower_First_Name;
  params.location_one.f_borrower_last_name = params.location_one.w_Borrower_Last_Name;
  if (params.location_one.w_Appointment_Time_type === 'OT') {
    params.location_one.w_Appointment_Time = '12:00 PM';
    params.location_one.f_appointment_time = '12:00 PM';
  } else {
    params.location_one.w_Appointment_Time = locationOneTime;
    params.location_one.f_appointment_time = locationOneTime;
  }
  if (params.location_two) {
    const locationTwoDate = moment(data.location_two.w_Appointment_Date).format('MM/DD/YYYY');
    const locationTwoTime = moment(data.location_two.w_Appointment_Time).format('hh:mm A');

    params.location_two.cansubmitinvoice = 1;
    params.location_two.f_offering_fee = 75.00;
    params.location_two.f_loan_number = 75.00;
    params.location_two.w_sql_appointment_date = moment(data.location_two.w_Appointment_Date, 'YYYY-MM-DD');
    params.location_two.w_Appointment_Date = locationTwoDate;
    params.location_two.f_appointment_date = locationTwoDate;
    params.location_two.f_borrower_first_name = params.location_two.w_Borrower_First_Name;
    params.location_two.f_borrower_last_name = params.location_two.w_Borrower_Last_Name;
    if (params.location_two.w_Appointment_Time_type === 'OT') {
      params.location_two.w_Appointment_Time = '12:00 PM';
      params.location_two.f_appointment_time = '12:00 PM';
    } else {
      params.location_two.w_Appointment_Time = locationTwoTime;
      params.location_two.f_appointment_time = locationTwoTime;
    }
  }

  return params;
};

export const createProfile = (data: any, initialValues: any) => {
 try {
  const values = { ...data }
  
  const CommisioningStates = []
  const prfCom = []
  data.CommisioningStates.forEach( (value: any) => {
    CommisioningStates.push(value.state)
  })

  data.prfCom.forEach( (value: any) => {
    prfCom.push(value.preferred_comm_value)
  })

  values['commisioning_states'] = CommisioningStates
  values['prfCom'] = prfCom
  return values
 } catch (error) {
   console.log(error)
   return initialValues
 }

}
