import React from 'react';
import { Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { languages } from '../../../Common/Rules/SignerForm';

const Languages = ({ values, setFieldValue }) => {

  const selectLanguage = (language) => {
    const languageIndex = values.other_language.split(',').indexOf(language.value)
    const value = values.other_language.split(',')
    if (languageIndex === -1) {
      value.push(language.value)
    } else {
      value.splice(languageIndex, 1)
    }
    setFieldValue(`other_language`, value.join(',') )
  }

  return (
      <Grid container spacing={2} md={12}>
        <Grid item spacing={2} sm={12} md={12}>
          <Typography> Languages </Typography>
        </Grid>
        {languages.map((language) => {
          return (
            <Grid item lg={4} sm={4} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.other_language.split(',').includes(language.value)}
                    value={language.value}
                    onChange={()=>{selectLanguage(language)}}
                    name="Languages"
                    color="primary"
                  />
                }
                label={language.name}
              />
            </Grid>
          )
        })}
      </Grid>
  );
}

export { Languages };
