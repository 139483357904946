import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import Box from '@mui/system/Box';


import { Fields } from './Fields';

import {
  findAuthorizedDomains,
  signup,
  sendEmailToRegister
} from '../../Recoil/Selectors/Register';

import {
  initialValues,
  validationSchema
} from '../../Common/Rules/RegisterForm';
import { marginStyles } from '../../Common/Styles/Margins';

const RegisterForm = ({ handleRegister }) => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any) => {
    let res = null;
    let method = '';
    const domainsRes: any = await snapshot.getPromise(
      findAuthorizedDomains(values)
    );
    if (domainsRes.result.authorized_domains) {
      const authorizedDomains = domainsRes.result.authorized_domains.split(
        ', '
      );
      const emailDomain = values.Signer_Email.split('@')[1];
      const find = authorizedDomains.find(e => e === emailDomain);
      values.user = values.Signer_Email;
      if (find) {
        // values.Company_ID = domainsRes.result.clientID;
        res = await snapshot.getPromise(signup(values));
        method = 'signup';
      } else {
        res = await snapshot.getPromise(sendEmailToRegister(values));
        method = 'email';
      }
    } else {
      res = await snapshot.getPromise(sendEmailToRegister(values));
      method = 'email';
    }

    // const firebaseRes: any = await snapshot.getPromise(firebaseSignup(values));

    if (!res.success) {
      setAlert({
        status: 'error',
        message: `Thank you for registering with us.\n
        A member of our Staff will contact you to finish your registration.`

      });
    } else {
      handleRegister(method);
    }
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        errors
      }) => (
        <form onSubmit={handleSubmit}>
          <Fields
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          {alert && (
            <Box my={2}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          )}
          <Box display='flex' justifyContent='flex-end' mt={3}>
            <Link to='/login'>
              <Button
                className={marginClasses.mr1}
                color='primary'
                size='small'
              >
                Cancel
              </Button>
            </Link>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              size='small'
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <CircularProgress
                  className={marginClasses.mr2}
                  color='inherit'
                  size={25}
                />
              )}
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export { RegisterForm };
